class HeaderProjet {

  constructor()
  {
    this.class = '.header-projet';
    this.$slider = $(this.class)

    if ( this.$slider.length === 0 ) return

    var slider = [{
      slider: this.$slider,
      args: {
        slidesToShow: 3,
        slidesToScroll: 3,
        prevArrow: '<a href="#" class="slick-prev"><i class="icon icon-arrow left"></i></a>',
        nextArrow: '<a href="#" class="slick-next"><i class="icon icon-arrow right"></i></a>',
        focusOnSelect: true,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToScroll: 2,
              slidesToShow: 2
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToScroll: 1,
              slidesToShow: 1
            }
          }
        ]
      }
    }]
    new SliderController( slider );
  }

}
new HeaderProjet()