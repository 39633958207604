class BannerHome {

    constructor() {
        this.class = '.banner-home';
        this.$section = $(this.class)
        this.$anchorClass = '.banner-home-anchor'

        if (this.$section.length === 0) return

        this.$section.on('click', this.$anchorClass, this.goToAnchorDestination.bind(this))

    }

    // pour aller à la section suivante / simuler une ancre dynamiquement
    goToAnchorDestination(e) {
        e.preventDefault()
        $('html, body').animate({ scrollTop: this.$section.next().position().top }, 'slow')
    }


}
new BannerHome()