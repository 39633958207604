var $ = jQuery;
(function ($) {

  $(document).ready(function () {

    var _app = [],
      _helpers = []

    _helpers['global'] = new GlobalController()
    _helpers['menu'] = new MenuController()
    _helpers['modal'] = new ModalController()
    _helpers['tracking'] = new TrackingController()
    _helpers['tracking-form'] = new TrackingFormController();
    _helpers['sticky-menu'] = new StickyMenuController()
    _helpers['anchor-menu'] = new AnchorController()

    _app['projects'] = new ProjectsController(_helpers)
    _app['footer'] = new FooterController(_helpers)

  })

})(jQuery)
