class SliderProjets {

  constructor()
  {
    var slider = [{
      slider: '.slider-projects-slider',
      args: {
        slidesToScroll: 1,
        slidesToShow: 1,
        prevArrow: '<a href="#" class="slick-prev"><i class="icon icon-arrow left"></i></a>',
        nextArrow: '<a href="#" class="slick-next"><i class="icon icon-arrow right"></i></a>',
        adaptiveHeight: true,
        focusOnSelect: true,
      }
    }]
    new SliderController( slider );
  }

}
new SliderProjets()