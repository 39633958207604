class AnchorController {

    constructor() {
        this.body = $('body')
        this.classAnchor = 'anchor-menu-link'
        this.classAnchorSmall = 'anchor-menu-small'
        this.classAnchorSmallTrigger = 'anchor-menu-small-trigger'
        this.classAnchorSmallContent = 'anchor-menu-small-content'
        this.classAnchorSmallContainer = 'anchor-menu-small-container'
        this.hash = window.location.hash.slice(1);

        this.initListeners()

        // savoir si on est dans le cas d'une ancre
        let url = window.location.href
        let idx = url.indexOf("#")
        let anchorUrl = idx != -1 ? url.substring(idx + 1) : "";
        if (anchorUrl) {
            // on scroll de 1px symbolique pour trigger la taille du menu sticky
            $('html').animate({ scrollTop: ($('body').position().top + 1) }, 'fast', () => {
                this.scrollToAnchor(anchorUrl, { speed: 'slow' })
            })
        }
    }

    initListeners() {
        let self = this
        self.body.on('click', '.' + self.classAnchor, self.triggerScroll.bind(this))
        self.body.on('click', '.' + self.classAnchorSmallTrigger, self.displayContentSmall.bind(this))
        self.body.on('click', 'header a', self.handleHashChange.bind(this))
        // $(window).bind('hashchange', self.handleHashChange.bind(this))
    }

    handleHashChange(e) {
        let self = this
        let url = window.location.pathname
        let href = $(e.currentTarget).attr('href')

        setTimeout(function () {
            let hash = window.location.hash.slice(1);

            if (typeof href != 'undefined' && href.indexOf(url) !== false) {
                self.scrollToAnchor(hash, {speed: 250})
            }
        }, 300)
    }

    triggerScroll(e) {
        e.preventDefault()

        let self = this
        let element = $(e.currentTarget)
        if (typeof(element.data('anchor')) != 'undefined' && element.data('anchor')) {
            let anchor = element.data('anchor')
            if ($('body').hasClass('affix-nav')) {
                this.scrollToAnchor(anchor, {})
            } else {
                // on scroll de quelques px symboliques pour trigger la taille du menu sticky
                $('html').animate({ scrollTop: ($('body').position().top + 20) }, 'fast', () => {
                    this.scrollToAnchor(anchor, { speed: 'slow' })
                })
            }
        }
    }

    scrollToAnchor(anchor, params) {
        let self = this
        let stringToSearch = '[id="' + anchor + '"]'
        if (self.body.find(stringToSearch).length) {
            let top = self.body.find(stringToSearch).first().position().top
            top += 1 // petit pixel de différence

            // on récupère la hauteur du header
            if ($('header.header').length) {
                top -= $('header.header').outerHeight()
            }
            // on récupère la hauteur du titre de la page
            if ($('.page-header-container').length) {
                top -= $('.page-header-container').outerHeight()
            }

            let speed = 'slow'
            if (params && typeof(params.speed != 'unfedined')) {
                speed = params.speed
            }
            // on scroll
            $('html').animate({ scrollTop: top }, speed)
            self.body.find('.' + self.classAnchorSmallContent).removeClass('show')
            self.body.find('.' + self.classAnchorSmallTrigger).removeClass('open')
            self.body.find('.' + self.classAnchorSmallContainer).removeClass('open')
        }
    }

    displayContentSmall(e) {
        if (e) {
            e.preventDefault()
        }
        let self = this
        self.body.find('.' + self.classAnchorSmallContent).toggleClass('show')
        self.body.find('.' + self.classAnchorSmallTrigger).toggleClass('open')
        self.body.find('.' + self.classAnchorSmallContainer).toggleClass('open')
    }

}