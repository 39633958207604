class SelectorController {

    constructor()
    {
        var that = this,
            has_selected = false;

        // Ajoute la gestion dynamique des select custom
        $('.acf-field-select .acf-input, .selector').each(function (e) {
            has_selected = true;
            that.selectCustom($(this));
        });

        if (has_selected) {
            $(document).on('click', function (e) {
                var element = $(e.target),
                    selector_container = element.closest('.selector'),
                    selector_container_acf = element.closest('.acf-field-select .acf-input');

                if (selector_container.length === 0 && !element.hasClass('.wrapper-select')) {
                    $('.selector').not(selector_container).removeClass('-open');
                }
                if (selector_container_acf.length === 0 && !element.hasClass('.wrapper-select')) {
                    $('.acf-field-select .acf-input').not(selector_container).removeClass('-open');
                }
            });
        }
    }

    selectCustom(selector)
    {
        var list = selector.find('.list');
        var current = selector.find('.current');
        var choice = list.find('.option');
        var selectTag = selector.find('select');

        current.click( function () {
            if (selector.hasClass('-open')) {
                selector.removeClass('-open');
            } else {
                selector.addClass('-open');
                $('.selector').not(selector).removeClass('-open');
            }
        } );
        if (selectTag.find('option:selected').length && selectTag.find('option:selected').val() !== 'false') {
            var text_selected = selectTag.find('option:selected').text();
            current.text(text_selected);
        }
        choice.on('click', function (e) {
            e.preventDefault();
            current.text( $(this).text() );
            selectTag.val( $(this).data('value') ).change();
            selector.removeClass('-open');
        } );
    }

    static resetSelector( selector ) {
        var current = selector.find('.current');
        var choice_default = selector.find('.option:first-child');
        var selectTag = selector.find('select');

        selectTag.val( choice_default.data('value') ).change();
        current.text( choice_default.text() )
    }
}
