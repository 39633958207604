class SectionMediatheque {

  constructor()
  {
    this.container_class = '.flex-media-slider'
    this.$container = $( this.container_class )

    if ( this.$container.length < 1 ) return

    var slider = [{
      slider: this.container_class,
      args: {
        slidesToShow: 4,
        slidesToScroll: 4,
        prevArrow: '<a href="#" class="slick-prev"><i class="icon icon-arrow left"></i></a>',
        nextArrow: '<a href="#" class="slick-next"><i class="icon icon-arrow right"></i></a>',
        focusOnSelect: true,
        responsive: [
          {
            breakpoint: 1700,
            settings: {
              slidesToScroll: 4,
              slidesToShow: 4
            }
          },
          {
            breakpoint: 992,
            settings: {
              slidesToScroll: 2,
              slidesToShow: 2
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToScroll: 1,
              slidesToShow: 1,
              arrows: false,
              dots: true,
            }
          }
        ]
      }
    }]
    new SliderController( slider );
  }

}
new SectionMediatheque()