class FooterController {

    constructor ( _helpers ) {
        $('#back-to-top').on('click', this.handleBackToTop.bind(this))
    }

    handleBackToTop(e) {
        e.preventDefault()
        document.body.scrollTop = 0 // For Safari
        document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
    }

}
