class ModalController {

    constructor() {
        var self = this;

        self.$body = $('body');

        self.$body.on('click', 'a.link-modal', function (e) {
            self.action  = $(this).attr('data-modal');
            self.$modal   = $('.modal[data-action="' + self.action + '"]');

            if ( self.$modal.length === 0 ) return

            e.preventDefault();
            self.$element = $(this);

            if ( self.action === 'modal-product-zoom' ) self.slider();
            else if ( self.action === 'e-retailer' ) self.e_retailer();

            self.open();

            return false;
        });
        self.$body.on('click', '.modal .modal-close, .modal .modal-overlay', function (e) {
            e.preventDefault();
            self.$modal = $(this).closest('.modal');
            self.close();
            return false;
        });
    }

    e_retailer() {
        var self = this,
            index = self.$element.data('index');

        self.$modal.find('.modal-body').addClass('hidden');
        self.$modal.find('[data-index="'+index+'"]').removeClass('hidden');
    }

    slider() {
        var self = this,
            $slides = self.$element.parent().find('.slide:not(.slick-cloned)').clone(),
            $modal_slider = self.$modal.find('.modal-body'),
            index_init = $('.tpl-product-slider-content').slick('slickCurrentSlide');

        $modal_slider.removeClass('slick-initialized slick-slider').html();
        $modal_slider.html($slides);
        $modal_slider.slick({
            dots: false,
            infinite: true,
            speed: 300,
            slidesToShow: 1,
            prevArrow: '<a href="#" class="slick-prev"><i class="fa fa-chevron-left"></i></a>',
            nextArrow: '<a href="#" class="slick-next"><i class="fa fa-chevron-right"></i></a>',
        }).slick('slickGoTo', index_init, false);
    }

    open() {
        var self = this;
        // modal.find('.modal-body').html('<div class="loader"></div>');

        this.$modal.show(150);
        this.$modal.addClass('-open');
        this.$body.addClass('disable-overflow');
    }

    close() {
        // modal.find('.modal-body').html('<div class="loader"></div>');
        this.$modal.hide(50);
        this.$modal.removeClass('-open');
        this.$body.removeClass('disable-overflow');
        this.action = this.$modal.data('action');

        if ( this.action === 'modal-video' ) this.$modal.find('.modal-body').html('');
    }

    changeZIndexTo( z_index ) {
        this.$modal.css('z-index', z_index);
    }
}
