class StickyMenuController {

    constructor() {
        this.$body = $('body');
        this.$header = $('header.header');
        this.$header_page = $('.page-header');
        this.$window = $(window)

        this.handleResizeHeightPageHeader()
        this.$window.on('scroll', this.sticky_nav.bind(this))
        this.$window.on('resize', this.handleResizeHeightPageHeader.bind(this))
        this.sticky_nav()
    }

    handleResizeHeightPageHeader() {
        this.$body.removeClass('affix-nav');
        this.$header_page.css('height', 'auto')
        this.$header_page.css('height', this.$header_page.innerHeight())
        this.sticky_nav()
    }

    sticky_nav() {
        var windowsTop = $(window).scrollTop();

        if ( windowsTop > 0 ) {
            this.$body.addClass('affix-nav');
        } else {
            this.$body.removeClass('affix-nav');
        }
    }
}
