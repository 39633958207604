class LoaderController {

    constructor() {
        var self = this;

        self.$container = $('.global-loader');
        self.seconds = 5;

        $(document).on('keyup', function(e) {
            if ( e.keyCode === 27 ) {
                self.hideLoader();
            }
        });

        self.$container.find('.error a').on('click', function(e) {
            self.hideLoader();
        });
    }

    showLoader() {
        this.$container.fadeIn(100);

        this.timeout = setTimeout(function ( _this ) {
            _this.showError();
        }, this.seconds * 1000 * 15, this);
    }

    hideLoader() {
        this.$container.fadeOut(50);
        clearTimeout( this.timeout );
        this.changeZIndexTo(999);
        this.$container.find('.error').hide();
    }

    showError() {
        this.$container.find('.error').show( 50 );
    }

    changeText( text ) {
        this.$container.find('.text').html( text );
    }

    changeZIndexTo( z_index ) {
        this.$container.css('z-index', z_index);
    }

}
