class OverlayController {

    constructor() {
        var that = this;

            that.$container = $('.global-overlay');

        $(document).on('keyup', function(e) {
            if ( e.keyCode === 27 ) {
                that.hideOverlay();
            }
        });
    }

    toggleOverlay() {
        var display =  this.$container.css("display");

        if ( display === "none" ) {
            this.showOverlay();
        } else {
            this.hideOverlay();
        }
    }

    showOverlay() {
        this.$container.fadeIn(100);
    }

    hideOverlay() {
        this.$container.fadeOut(50);
        this.changeZIndexTo(999);
    }

    changeZIndexTo( z_index ) {
        this.$container.css('z-index', z_index);
    }

}
