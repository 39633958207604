class SliderController {

    constructor( sliders ) {

        var that = this;

        that.cpt_reinit = 0;
        that.sliders = sliders;
        that.init();

        $(document).ajaxComplete(function () {
            that.reinit();
        });
    }

    init()
    {
        jQuery.each(this.sliders, function (i, val) {
            var $slider = $( val.slider );

            if ( $slider.length ) {
                $slider.slick( val.args );
            }
        });
    }

    reinit()
    {
        var that = this;
        that.cpt_reinit++;
        var cpt = that.cpt_reinit;

        setTimeout(function () {
            if ( cpt === that.cpt_reinit ) {
                jQuery.each(that.sliders, function (i, val) {
                    var $slider = $( val.slider );

                    if ( $slider.length && ! $slider.hasClass('slick-initialized') ) {
                        $slider.slick( val.args );
                    }
                });
            }
        }, 300);
    }
}
